<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td class="font-weight-medium">{{ item.title }}</td>
            <td v-if="item.isHtml" v-html="item.value"></td>
            <td v-else-if="item.value.length > 150">
              <v-btn @click="openAllItemsModal(item.value)" color="primary" text x-small> Показати всі </v-btn>
            </td>
            <td v-else>{{ item.value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <AllItemsModal ref="allItemsModal" />
  </div>
</template>

<script>
export default {
  name: 'SettingTable',

  components: {
    AllItemsModal: () => import('@/components/dialogs/AllItemsModal'),
  },

  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    openAllItemsModal(items, instanceName, dictionary) {
      this.$refs.allItemsModal.showDialog(items, instanceName, dictionary)
    },
  },
}
</script>
